import React from "react";
import "./Biography.css";
import bioImage from "../../imgs/echo2.jpg";
import image1 from "../../imgs/the-lab4.jpg"; 
import image2 from "../../imgs/StudioB.jpg"; 
import image3 from "../../imgs/StudioA.jpg"; 

function Biography() {
  return (
    <div className="biography-section">
      <div className="biography-container">
        <div className="biography-image">
          <img src={bioImage} alt="Echo at The Lab Studios" />
        </div>
        <div className="biography-content">
        <h1 className="biography-title">Our Journey in the Music Industry</h1>
          <p className="biography-text">
            Echo, a visionary music producer, engineer, and entrepreneur, is the driving force behind THE LAB STUDIOS in Miami and San Juan. With a passion for innovation and excellence, Echo has transformed these studios into creative hubs where artistry and technical precision converge, producing chart-topping hits that redefine the music landscape.
          </p>
          <p className="biography-text">
            Over the past three decades, Echo has collaborated with world-renowned artists, including Tego Calderón, De La Ghetto, J King & Maximan, and Pitbull. His ability to blend urban and pop sounds has resonated with audiences across Latin, mainstream, and European markets, solidifying his reputation as a pioneer in music production.
          </p>
          <p className="biography-text">
            Echo’s dedication to quality and his relentless pursuit of perfection have earned him four Grammy Awards, positioning him as one of the most influential figures in modern music production. Beyond his personal accolades, he has built a team of talented engineers, songwriters, and producers at THE LAB STUDIOS, fostering a culture of innovation and excellence.
          </p>
          <p className="biography-text">
            From humble beginnings to international acclaim, Echo’s journey has been marked by an unwavering commitment to pushing boundaries and breaking barriers. Today, THE LAB STUDIOS stands as a testament to his vision—a place where dreams take shape and the soundtracks of tomorrow are born.
          </p>
        </div>
      </div>

      <div className="biography-gallery">
        <div className="gallery-item">
          <img src={image1} alt="Inside The Lab Studios" />
          
        </div>
        <div className="gallery-item">
          <img src={image2} alt="Studio B Collaboration" />
          
        </div>
        <div className="gallery-item">
          <img src={image3} alt="Echo in Action" />
          
        </div>
      </div>
    </div>
  );
}

export default Biography;