import React, { useEffect}  from 'react';
import '../App.css';
import HeroSection from '../containers/Hero/MainHeroSection';
import Studios from '../containers/Studios/StudiosSection';
import ProjectsPage from './Projects';
import Bio from '../containers/BioPreview/BioPreviewSection';
import Footer from '../containers/Footer/Footer';




function Home() {

    useEffect(() => {

        // const previousOverflow = document.body.style.overflow;
    
        // Disable scrolling
        // document.body.style.overflow = 'hidden';
    
        return () => {
          // Re-enable scrolling when the component is unmounted
          // document.body.style.overflow = previousOverflow;
        };
      }, []);
    
      return (
        <>
          <HeroSection />
          <Bio />
          <ProjectsPage />
          <Studios />
          <Footer />
        </>
        
      );
    }

export default Home;




