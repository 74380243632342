// 0aea328e-896e-4cf6-9df5-ba32d07a6a5c

import React, { useState } from 'react';
import virusLogo from '../../imgs/artists_walls.jpg';
import { FaPhoneAlt, FaSms, FaEnvelope } from 'react-icons/fa';
import './ContactSection.css';

function ContactSection() {
    const [formStatus, setFormStatus] = useState('');
    const [showBanner, setShowBanner] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      answer: ''
    });
    const [mathQuestion, setMathQuestion] = useState(generateMathQuestion());
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (parseInt(formData.answer) !== mathQuestion.correctAnswer) {
        setFormStatus('Incorrect answer to the math question.');
        setShowBanner(true);
        setTimeout(() => setShowBanner(false), 3000);
        return;
      }
  
      setFormStatus('Submitting...');
  
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          access_key: '0aea328e-896e-4cf6-9df5-ba32d07a6a5c',
          ...formData
        })
      });
  
      const result = await response.json();
      if (result.success) {
        setFormStatus('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          message: '',
          answer: ''
        });
        setMathQuestion(generateMathQuestion()); // Reset the math question
        setShowBanner(true);
        setTimeout(() => setShowBanner(false), 3000);
      } else {
        setFormStatus('Failed to send message.');
        setShowBanner(true);
        setTimeout(() => setShowBanner(false), 3000);
      }
    };
  
    function generateMathQuestion() {
      const num1 = Math.floor(Math.random() * 10) + 1;
      const num2 = Math.floor(Math.random() * 10) + 1;
      return {
        question: `${num1} + ${num2} = ?`,
        correctAnswer: num1 + num2
      };
    }
  
    return (
      <div className="contact-page-container">
        {showBanner && (
          <div className={`success-banner ${formStatus.includes('successfully') ? 'show' : ''}`}>
            {formStatus.includes('successfully') ? 'Message sent successfully!' : formStatus}
          </div>
        )}
        <div className="contact-header">
          <img src={virusLogo} alt="Kinto Sol" className="header-image" />
        </div>
        <div className="contact-content">
          <div className="booking-info">
            <h2>BOOKING</h2>
            <p><FaPhoneAlt className="icon" />Office: (414) 647-8495</p>
            <p><FaSms className="icon" />Text: (414) 232-4057</p>
            <p><FaEnvelope className="icon" /> Email: <a href="mailto:kintosolmusica@yahoo.com">&nbsp;kintosolmusica@yahoo.com</a></p>
            <p className="serious-inquiries">We appreciate your interest! To make the most of our time together, please reach out with genuine inquiries and provide detailed information about your request. Thank you!</p>
          </div>
          <div className="contact-form">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name <span className="required">*</span></label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
              
              <label htmlFor="email">Email <span className="required">*</span></label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
              
              <label htmlFor="message">Message <span className="required">*</span></label>
              <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
  
              <label htmlFor="answer">{mathQuestion.question} <span className="required">*</span></label>
              <input type="text" id="answer" name="answer" value={formData.answer} onChange={handleChange} required />
              
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
  
  export default ContactSection;