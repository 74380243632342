import React, { useState } from 'react';
import './Services.css';

const services = [
  {
    id: 'production',
    title: 'Creative Production',
    content:
      'Our team of world-class producers and composers is dedicated to bringing your artistic vision to life. Whether you need a bespoke beat or a fully produced song, we specialize in crafting music that reflects your unique style and identity. Collaborate with us to create something entirely tailored to you, or explore our extensive catalogue of pre-composed ideas, beats, and music. From brainstorming to production, we ensure every note resonates with your creative intent, giving you the tools to stand out in the industry.',
    image: '/imgs/services/mixing-echo.jpg',
  },
  {
    id: 'recording',
    title: 'Recording',
    content:
      'At The Lab Studios, we pride ourselves on being a home to some of the most influential names in the music industry. Icons like Daddy Yankee, Tego Calderon, Don Omar, Wisin & Yandel, Ricky Martin, Nicky Jam, Gente de Zona, and Peso Pluma have graced our state-of-the-art recording rooms. Whether you’re a seasoned artist or an up-and-coming talent, our studio offers the perfect environment for capturing your sound with precision and clarity. With cutting-edge technology and a team of experienced engineers, we ensure your recordings are nothing short of legendary.',
    image: '/imgs/services/mastering-echo.jpg',
  },
  {
    id: 'mixandmaster',
    title: 'Mix & Master',
    content:
      'Elevate your music to a professional level with our comprehensive mixing and mastering services. From intricate post-editing and polished vocal productions to sound design that transforms your tracks into immersive experiences, we handle it all. Whether you’re working on music, film scores, or multimedia projects, we cater to artists at every stage of their career. Our expertise guarantees a dynamic and balanced sound that translates seamlessly across platforms, ensuring your work resonates with audiences worldwide.',
    image: '/imgs/services/dolby.jpg',
  },
  {
    id: 'surroundSoundMixing',
    title: '5.1 Surround Sound Mixing',
    content:
      'Step into the future of audio with Dolby Atmos, a revolutionary approach to sound that places every element exactly where it needs to be. This cutting-edge technology allows us to create immersive, three-dimensional soundscapes that envelop listeners, taking them beyond the boundaries of traditional stereo or surround sound. Perfect for music, film, and innovative audio projects, Dolby Atmos transforms how audiences experience your work, making every note and sound more vivid, realistic, and emotionally impactful.',
    image: '/imgs/services/surround-sound.jpg',
  },
];

const Services = () => {
  const [activeTab, setActiveTab] = useState(services[0].id);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <div className="services-container">
      {/* Pre-accordion Intro */}
      <div className="services-intro">
        <h2 className="intro-title">Experience Professional Audio Production</h2>
        <p className="intro-description">
          At The Lab Studios, we offer an extensive range of services to cater to every aspect of your audio production needs. From the creativity of custom production to the precision of mixing, mastering, and immersive audio experiences like Dolby Atmos, we ensure your music or project reaches its fullest potential.
        </p>
      </div>

      <h2 className="services-title">Our Services</h2>
      <div className="services-divider"></div>

      <div className="services-layout">
        {/* Accordion Section */}
        <div className="accordion-section">
          {services.map((service) => (
            <div
              key={service.id}
              className={`accordion-item ${activeTab === service.id ? 'active' : ''}`}
              onClick={() => handleTabClick(service.id)}
            >
              <h3 className="accordion-title">
                {service.title}
                <span className={`accordion-icon ${activeTab === service.id ? 'open' : ''}`}>
                  {activeTab === service.id ? '−' : '+'}
                </span>
              </h3>
              {activeTab === service.id && (
                <div className="accordion-content">
                  <p>{service.content}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Image Display */}
        <div className="image-display">
          <img
            className="service-image"
            src={services.find((service) => service.id === activeTab).image}
            alt={services.find((service) => service.id === activeTab).title}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;