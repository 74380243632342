import React from "react";
import "./StudiosSection.css";

// Import images
import StudioA from "../../imgs/StudioA.jpg";
import StudioB from "../../imgs/StudioB.jpg";
import StudioC from "../../imgs/StudioC.jpg";



const studios = [
  {
    name: "Studio A",
    type: "Studios Specs",
    description: "A top-notch studio designed for professional mixing sessions.",
    specs: [
      "SOLID STATE LOGIC ORIGIN CONSOLE",
      "AUGSPURGER DUO 8” WITH DUAL 12” SUBWOOFERS",
      "EMPIRICAL LABS EL8-XS DISTRESSORS PAIR W/BRITISH",
      "MODE & ST LINK",
      "RUPERT NEVE SHELFORD CHANNEL STRIP",
      "AVALON DESIGNS VT- 737 CHANNEL STRIP",
      "AVID PRO TOOLS HDX TB3 CHASSIS WITH HDX CORE",
      "ANTELOPE AUDIO ORION 32 HD GEN 3 AUDIO INTERFACE",
      "CRANE SONGS HEDD 192KHZ AD/DA CONVERTER",
      "C ELECTRONICS FINALIZER",
      "SHADOW HILLS VANDERGRAPH ST COMPRESSOR",
      "SSL 4000 BUS COMPRESSOR",
      "STAM AUDIO 312C 500 SERIES TUBE PREAMPS",
    ],
    rate: "$95/hour",
    minimumHours: "3 hours minimum",
    engineerNotIncluded: true,
    image: StudioA, // Replace with actual image path
  },
  {
    name: "Studio B",
    type: "Studios Specs",
    description: "Perfect for capturing vocals and acoustic instruments.",
    specs: [
      "SOLID STATE LOGIC AWS 948 DELTA",
      "FOCAL TRIO 11 3-WAY MONITORS",
      "RUPERT NEVE SHELFORD CHANNEL STRIP",
      "AVALON DESIGNS VT- 737 CHANNEL STRIP",
      "TUBE TECH ME-1CA TUBE CHANNEL STRIP",
      "AVID PRO TOOLS HDX TB3 CHASSIS WITH HDX CORE",
      "UA QUAD TB2 SATELLITE DSP PROCESSOR UNIT",
      "ANTELOPE AUDIO ORION 32 HD GEN 3 AUDIO INTERFACE",
      "SSL ALPHA LINK MADI INTERFACE",
      "CRANE SONGS HEDD 192KHZ AD/DA CONVERTER",
      "TC ELECTRONICS FINALIZER",
      "SHADOW HILLS VANDERGRAPH ST COMPRESSOR",
      "SSL 4000 BUS COMPRESSOR",

    ],
    rate: "$100/hour",
    minimumHours: "3 hours minimum",
    engineerNotIncluded: true,
    image: StudioB, // Replace with actual image path
  },
  {
    name: "Studio C",
    type: "Studios Specs",
    description: "Equipped with state-of-the-art mastering equipment.",
    specs: [
      "7.4.1 ",
      "FOCAFOCAL ATMOS RIG",
      "KALI AUDIO SUB",
      "APOLLO X6 INTERFACE",


    ],
    rate: "$85/hour",
    minimumHours: "2 hours minimum",
    engineerNotIncluded: true,
    image: StudioC, // Replace with actual image path
  },
];

function StudiosSection() {
  return (
    <div className="Studios-section">
    <h2 className="Studios-heading">Our Recording Studios</h2>
    <p className="Studios-subheading">
      Each of our studios is equipped with the finest technology and experienced sound engineers to ensure top-quality results.
    </p>
    <div className="Studios-grid">
      {studios.map((studio, index) => (
        <div key={index} className="Studio-card">
          <div
            className="Studio-image"
            style={{ backgroundImage: `url(${studio.image})` }}
            aria-label={`${studio.name} image`}
          ></div>
          <div className="Studio-content">
            <h3 className="Studio-name">{studio.name}</h3>
            <p className="Studio-type">{studio.type}</p>
            <p className="Studio-description">{studio.description}</p>
            <ul className="Studio-specs">
              {studio.specs.map((spec, idx) => (
                <li key={idx} className="Studio-spec-item">
                  {spec}
                </li>
              ))}
            </ul>
            <div className="Studio-rate">
              <span className="rate">{studio.rate}</span>
              <span className="minimum-hours"> • {studio.minimumHours}</span>
            </div>
            <div className="Studio-engineer">
              {studio.engineerNotIncluded && "Sound Engineer Not Included"}
            </div>
            {/* Add Book Now button */}
            <button className="book-now-button">Book Now</button>
          </div>
        </div>
      ))}
    </div>
  </div>
);
}

export default StudiosSection;