import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="ticketmaster-footer">
      <div className="footer-container">
        {/* Logo Section */}
        <div className="footer-logo-section">
          <img
            src={`${process.env.PUBLIC_URL}/assets/thelab-logo-white.png`}
            alt="The Lab Studios Logo"
            className="footer-logo"
          />
          <p className="footer-tagline">
            The Lab Studios: Where Creativity Meets Innovation.
          </p>
        </div>

        {/* Navigation Section */}
        <div className="footer-nav-section">
          <h3 className="footer-heading">Quick Links</h3>
          <ul className="footer-nav">
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="footer-contact-section">
          <h3 className="footer-heading">Contact Us</h3>
          <p>
            Phone: <a href="tel:(954)890-0701">(954) 890-0701</a>
          </p>
          <p>
            Phone: <a href="tel:(305)303-9314">(305) 303-9314</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:info@thelabstudiosmiami.com">
              info@thelabstudiosmiami.com
            </a>
          </p>
          <p>2574 Tequesta Ln, Miami, FL 33133</p>
        </div>

        {/* Social Media Section */}
        <div className="footer-social-section">
          <h3 className="footer-heading">Follow Us</h3>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/thelabstudiosmiami"
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/thelabstudiosmiami_/"
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/c/thelabstudiosmiami"
              aria-label="YouTube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="footer-bottom">
        <p>
          © 2025 Echosistema / The Lab. All Rights Reserved. | Website by Eugenio Espinoza
        </p>
      </div>
    </footer>
  );
}

export default Footer;