import React from "react";
import "./ProjectsSection.css";

// Dynamically import all images from the folder
const importAll = (r) => r.keys().map(r);
const covers = importAll(require.context("../../imgs/projects", false, /\.(png|jpe?g|svg)$/));

// Fixed positions for each image
const fixedPositions = [
  { top: "20%", left: "10%" },
  { top: "25%", left: "20%" },
  { top: "15%", left: "35%" },
  { top: "30%", left: "40%" },
  { top: "10%", left: "60%" },
  { top: "35%", left: "70%" },
  { top: "50%", left: "15%" },
  { top: "55%", left: "30%" },
  { top: "45%", left: "50%" },
  { top: "60%", left: "65%" },
  { top: "65%", left: "80%" },
];

const ProjectsSection = () => {
  return (
    <div className="projects-section">
      <div className="content-container">
        <h1 className="headline">Latest Projects</h1>
        <p className="subheadline">Exploring the newest sounds and productions</p>
      </div>
      <div className="cover-art-container">
        {covers.map((cover, index) => (
          <img
            key={index}
            src={cover}
            alt={`Cover ${index + 1}`}
            className="cover-art"
            style={fixedPositions[index] || {}} // Apply fixed positions or fallback to default
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;