import React, { useState, useEffect } from 'react';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // Add scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle resizing to close the mobile menu
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setClick(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      {/* Desktop Navbar */}
      <div className="navbar-left">
        <a href="/" onClick={closeMobileMenu}>HOME</a>
        <a href="/services" onClick={closeMobileMenu}>SERVICES</a>
        <a href="/projects" onClick={closeMobileMenu}>PROJECTS</a>
      </div>
      <div className="navbar-center">
        <img
          src={process.env.PUBLIC_URL + '/assets/thelab-logo.png'}
          alt="Logo"
          className="logo-image"
        />
      </div>
      <div className="navbar-right">
        <a href="/aboutus" onClick={closeMobileMenu}>ABOUT US</a>
        <a href="/" onClick={closeMobileMenu}>CONTACT</a>
      </div>

      {/* Mobile Navbar */}
      <div className="navbar-mobile">
        <img
          src={process.env.PUBLIC_URL + '/assets/thelab-logo.png'}
          alt="Logo"
          className="logo-image"
        />
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      </div>
      <div className={`navbar-menu ${click ? 'open' : ''}`}>
        <a href="/" onClick={closeMobileMenu}>HOME</a>
        <a href="/services" onClick={closeMobileMenu}>SERVICES</a>
        <a href="/projects" onClick={closeMobileMenu}>PROJECTS</a>
        <a href="/aboutus" onClick={closeMobileMenu}>ABOUT US</a>
        <a href="/contact" onClick={closeMobileMenu}>CONTACT</a>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeMobileMenu}
          >
            <i className="fab fa-facebook-f" />
          </a>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeMobileMenu}
          >
            <i className="fab fa-instagram" />
          </a>
          <a
            href="https://www.youtube.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeMobileMenu}
          >
            <i className="fab fa-youtube" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;